

$breakpoint:992px !default;
$scale:14px !default;

.layout-dark {
    --sidebar-shadow: none;
    --sidebar-border: 1px solid var(--surface-border);
    --card-shadow: none;
    --body-bg:linear-gradient(180deg, #2E323F 0%, #0A061A 100%) ;
    --body-image:  url("/assets/layout/images/effect-ondark.png");
    --root-menu-item-hover-bg:rgba(255, 255, 255, 0.05);
    --exception-pages-image: url('/assets/layout/images/pages/exception-ondark.png');
}


