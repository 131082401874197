/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";


@font-face {
    font-family: 'Roboto Condensed';
    src: url('assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Condensed Light';
    src: url('assets/fonts/RobotoCondensed-Light.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Condensed Bold';
    src: url('assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Roboto Light';
    src: url('assets/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


.p-tag-icon, .p-tag-value, .p-tag-icon.pi {
    padding-top: 5px !important;
}

.p-tag-value {
    margin-bottom: 5px !important;
}

.muni-table-head-inputgroup {
    display: flex;
    align-items: stretch;
    width: 100%
}

.card-muni {
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 2px 2px;
    box-sizing: border-box;
    margin-bottom: 0;
    width: 100%;
}

.p-timeline-event-opposite, .p-timeline-event-content {
    flex: unset !important;
}

.page-title {
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    padding: 8px;
    box-sizing: border-box;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: white !important;
}

.p-paginator-rpp-options {
    min-height: 30px !important;
}

.p-inplace .p-inplace-display {
    display: inline-block !important;
    width:100%;
}

.card-author {
    border-radius: 8px;
    background: var(--surface-card);
    box-sizing: border-box;
    width:100%;
}

.card-date-status {
    border-radius: 8px;
    background: var(--surface-card);
    box-sizing: border-box;
    width:100%;
}



.buttonSetWidth {
    min-width: 120px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.captionWidth {
    min-width: 150px;
    width: 100%;
}


/* Works on Chrome, Edge, and Safari */

:root {
    --scroll-bar-color: #404c50;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scroll-bar-color) !important;
    border-radius: 20px;
    border: 3px solid var(--scroll-bar-bg-color);
}

/* Those are added */

.center-text {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-align {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.login-link {
    margin-top: 5px;
    margin-bottom: 2rem;
    margin-left: 10px;
    color: var(--orange-400)  !important;
    text-align: right;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    /*text-decoration: underline;*/
}

.login-info {
    margin-top: 5px;
    margin-bottom: 2rem;
    margin-left: 10px;
    color: var(--bluegray-200)  !important;
    text-align: right;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.municipality-label {
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: var(--bluegray-200)  !important;
    text-align: center;
    font-size: 0.9rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
}

.municipality-name {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
    color: var(--bluegray-100)  !important;
    text-align: center;
    font-size: 0.9rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
}


.custom-checkbox {
    margin: 6px;
}

.muniUpload-noDocs .p-panel-header {
    background: darkorange;
}

.muniUpload-docs .p-panel-header {
    background: teal;
}

.muni-image-container {
    max-height: 330px;
    overflow: hidden;
}

.muni-image-container img {
    width: 100%;
    height: auto;
}

.muni-field-height {
    height: 30px !important;
}


// ******************************************************************************************
// *************** TextStyles for Article        ***************



.komunity-chip {
    max-width:180px;
    min-width:100px;
    height: 28px;

    border-radius: 8px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 10px;
    margin-right: 2px;

    background-color: var(--pink-100);
    color: white;

    display: flex;        /* Use Flexbox */
    align-items: center;
    text-align: left;
    white-space: nowrap;  /* Keep text on a single line */
    overflow: hidden;     /* Hide any overflowing content */
    text-overflow: ellipsis;
}

.article-title {
    margin-top: 10px;
    color: var(--bluegray-200)  !important;
    text-align: left;
    font-size: 1.2rem;
    font-family: 'Roboto Condensed Bold', sans-serif;
    white-space: pre-wrap;
    margin-bottom: 16px;
}

.article-text {
    margin-top: 5px;
    color: var(--bluegray-200)  !important;
    text-align: left;
    font-size: 1rem;
    font-family: 'Roboto Condensed Thin', sans-serif;

    white-space: pre-wrap;
    margin-bottom: 16px;
}

// *************** This is grouped items stacked in a box with a title        ***************

.text-card {
    position: relative;
    margin-top: 15px;
    margin-left: 2%;
    margin-bottom: 12px;
    margin-right: 2%;
    width: 100%;
    min-width:300px;
    background: rgba(0, 0, 0, 0.01);
    padding: 10px;
}

.group-card-inside {
    display: flex;
    flex-direction: column;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;

    margin-top: 35px;
    margin-left: 0px;
    margin-bottom: 12px;
    width: 100%;

    background: rgba(0, 0, 0, .05);
    border: 0.1px solid var(--gray-600);
    border-top-left-radius: 8px;
    padding:0;
    box-sizing: border-box;
}

.group-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.group-title-inside {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width:200px;
    height:26px;
    top: 0;
    left: -1px;                   /* Matches the padding-left of the .card-group */
    margin-top:0;
    margin-left:0;
    margin-right:10px;
    margin-bottom:0px;
    //     color: var(--bluegray-100)  !important; //#0e0e0e;                /* Styling for the title */
    //     background-color: var(--bluegray-700); /*  #ffcc33;  var(--orange-200);  Semi-transparent white for the oval background */
    background-color: var(--secundary-color);
    border-top-left-radius: 8px; /* Rounded top-left corner */
    border-top-right-radius: 0px; /* Rounded top-right corner */
    border-bottom-left-radius: 8px; /* Straight bottom-left corner */
    border-bottom-right-radius: 8px;

    padding: 0px 10px; /* Adjust these values for the desired spacing around the text inside the oval */
    white-space: nowrap;  /* Keep text on a single line */
    overflow: hidden;     /* Hide any overflowing content */
    text-overflow: ellipsis;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1rem;
}


.group-stacked-inside {
    display: flex;
    flex-wrap: wrap;
    gap: 2px; /* This will ensure there's a gap between the items horizontally and vertically */
}

.komunity-chip-inside {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;
    border-radius: 4px !important;

    height: 22px;
    min-width:60px !important;
    max-width:220px !important;
    padding: 2px;
    padding-left: 4px;
    padding-right: 8px;
    margin:2px;
    border: 0.1px solid var(--gray-600);
    background-color: var(--primary-color);
    display: flex;        /* Use Flexbox */
    align-items: center;
    text-align: left;
    white-space: nowrap;  /* Keep text on a single line */
    overflow: hidden;     /* Hide any overflowing content */
    text-overflow: ellipsis;
    flex-grow: 0;  /* Takes up the remaining space */
    flex-wrap: wrap;
    gap: 1px;  /* Adjust this for the desired spacing between chips */
}

.komunity-chip-inside i,
.komunity-chip-inside span.pi-chip-icon {
    transform: scale(0.8);
}

.group-card {
    position: relative;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;

    margin-top: 35px;
    margin-left: 0px;
    margin-bottom: 12px;
    width: 100%;

    background: rgba(0, 0, 0, 0.01);
    border: 0.1px solid var(--gray-600);
    border-top-left-radius: 0px; /* Rounded top-left corner */
    border-top-right-radius: 8px; /* Rounded top-right corner */
    border-bottom-left-radius: 8px; /* Straight bottom-left corner */
    border-bottom-right-radius: 8px;

    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 8px;
    box-sizing: border-box;
}

.budget-card {
    position: relative;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;

    margin-top: 35px;
    margin-left: 0px;
    margin-bottom: 12px;
    width: 100%;

    background: rgba(0, 0, 0, 0.01);
    border: 0.1px solid var(--gray-600);
    border-top-left-radius: 0px; /* Rounded top-left corner */
    border-top-right-radius: 8px; /* Rounded top-right corner */
    border-bottom-left-radius: 8px; /* Straight bottom-left corner */
    border-bottom-right-radius: 8px;

    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 8px;
    box-sizing: border-box;
}

.group-title {
    position: absolute;
    width:180px;
    height:20px;
    top: 0;
    left: -0.5px;                   /* Matches the padding-left of the .card-group */
    transform: translateY(-100%);   /* Push the title up by half of its height */
    color: var(--bluegray-900)  !important; //#0e0e0e;                /* Styling for the title */
    background-color: var(--bluegray-300); /*  #ffcc33;  var(--orange-200);  Semi-transparent white for the oval background */
    border-top-left-radius: 8px; /* Rounded top-left corner */
    border-top-right-radius: 28px; /* Rounded top-right corner */
    border-bottom-left-radius: 0px; /* Straight bottom-left corner */
    border-bottom-right-radius: 0px;

    padding: 0px 19px; /* Adjust these values for the desired spacing around the text inside the oval */
    white-space: nowrap;  /* Keep text on a single line */
    overflow: hidden;     /* Hide any overflowing content */
    text-overflow: ellipsis;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.05rem;
}


.group-stacked {
    display: flex;
    flex-wrap: wrap;
    gap: 6px; /* This will ensure there's a gap between the items horizontally and vertically */
}

// ************ This is DateTime placed on top right corner over the image *****************

.group-datetime {
    position: absolute;
    top: 0;
    right: 0;
    width:200px;
    height:28px;
    background-color: var(--pink-500);
    display: flex;
    align-items: center;
    text-align:right;
    padding: 0px 0px 2px 22px; /* top, right, bottom, left*/
    border-top-left-radius: 0px; /* Rounded top-left corner */
    border-top-right-radius: 8px; /* Rounded top-right corner */
    border-bottom-left-radius: 28px; /* Straight bottom-left corner */
    border-bottom-right-radius: 0px;
}


.group-datetime .pi {
    margin-left: 10px;
    color:  #fefefe; /* This will make the icons same color as your text */
}

.group-datetime-text{
    color:  #fefefe;
    padding: 0px;
    text-align: left;
    margin-left: 2px;
    margin-right:4px;

    white-space: nowrap; /* Keeps the text on one line */
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    font-size:1rem;
}

// ************ This is Municipality Logo *****************
.opshtina-container {
    display: flex;
    justify-content: space-between;  /* This will ensure that there's space between the two children */
}

.opshtina-wrapper {
    //     flex: 1;  /* This will make each opshtina-wrapper grow to fill 50% of the container */
    margin-right:  0px;
    width: 100%; // calc(50% - 60px);
    height: 80px;
    padding:0;
    position: relative; /* To position the image container absolute to this div */
}

.opshtina-card {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    height: 60px;
    top: 0px;
    left: 60px;
    padding: 8px;
    border: 0.1px solid var(--gray-500);
    border-left:0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
}

.opshtina-logo {
    position: absolute;
    overflow: hidden;
    top: -0.5px;
    left: 0.2px;
    width: 60px;
    height: 60px;
    border-radius: 8px 0px 0px 8px;                 /* top-left, top-right, bottom-right, bottom-left */
    border: 0.0px solid rgba(255, 255, 255, 0.0005); /* 0.5px border with 0.5% transparent white color */
    background: rgba(255, 255, 255, 0.1); /* 0.2% transparent white */
}

.opshtina-logo img {
    max-width: 40px;
    max-height: 40px;
    width: auto;
    height: auto;
    display: block;
    margin: 10px auto; /* Center the image horizontally */
}

.opshtina-label, .opshtina-name {
    white-space: nowrap; /* Keep the text on a single line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Display ellipsis for overflow */
    font-size: 1rem; /* Set font size */
}

.opshtina-label {
    font-family: 'Roboto Light', sans-serif;
    margin-bottom: 3px; /* space between the two lines */
}

.opshtina-name {
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.1rem;
}


// ****************** Image Gallery in Views ******************

.gallery-container  {
    position: relative;
    height: 350px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.gallery-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.gallery-nav-btn {
    position: absolute;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust positioning to be perfectly centered */
    background-color: rgba(255, 255, 255, 0.4); /* Slight white background */
    border: none;
    font-size: 18px;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 50%; /* Make buttons circular */
}

.gallery-nav-btn:hover {
    background-color: rgba(241, 109, 22, 0.99); /* Stronger background on hover */
}

.gallery-prev-btn {
    left: 10px; /* Position to the left */
}

.gallery-next-btn {
    right: 10px; /* Position to the right */
}


// ****************** Author Placed with name and Logo on bottom left position of Gallery

.author-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 32px;
    width: 100%;
    background: linear-gradient(to left, transparent 0%, rgba(56, 58, 100, 1) 90%, rgba(56, 58, 100,  0.4) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0e0e0e;
}

.author-label {
    color:  #fefefe;
    text-align: left;
    margin-bottom: 1px;
    margin-left: 75px;
    margin-right: auto;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 1rem;
}

.author-name {
    padding: 2px;
    margin-bottom: 1px;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


//  ****************** Author Placed with name and Logo on bottom left position of Gallery

.author-avatar-container-background {
    position: absolute;
    bottom: 0px;
    left: 2px;
    width: 60px;
    height: 60px;
    background-color: rgba(56, 58, 100, 1);
    border-radius: 50%;
    display: flex;
}

.author-avatar-container {
    position: absolute;
    bottom: 6px;
    left: 6px;
    width: 48px;
    height: 48px;
    border-radius: 50%; /* Makes the container circular */
    display: flex;
    overflow: hidden; /* Hides the excess portion of the image */
}

.author-avatar-container img {
    object-fit: cover; /* Scales and crops the image */
    width: 100%;
    height: 100%;
}


//  ****************** Status together with the date time when it was last changed ******************

.status-card {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 60px;
    padding: 8px;
    border: 0.1px solid var(--gray-500);
    border-radius: 8px;
}

.status-datetime {
    position: absolute;
    top: 0;
    right: 0;
    width:100%;
    height:calc(50% - 1px);
    background-color: var(--primary-400);
    display: flex;
    text-align:right;
    padding: 6px 0px 0px 22px; /* top, right, bottom, left*/

    border-top-left-radius: 8px; /* Rounded top-left corner */
    border-top-right-radius: 8px; /* Rounded top-right corner */
    border-bottom-left-radius: 0px; /* Straight bottom-left corner */
    border-bottom-right-radius: 0px;
}

.status-status {
    position: absolute;
    top: calc(50% + 2px);
    right: 0;
    bottom:0;
    width:100%;
    height:calc(50% - 1px);
    background-color: var(--bluegray-600);
    display: flex;
    text-align:right;
    padding: 6px 0px 0px 22px; /* top, right, bottom, left*/

    border-top-left-radius: 0px; /* Rounded top-left corner */
    border-top-right-radius: 0px; /* Rounded top-right corner */
    border-bottom-left-radius: 8px; /* Straight bottom-left corner */
    border-bottom-right-radius: 8px;
}

textarea[formControlName="headline"] {
    resize: vertical;
    margin-top:-10px;
    margin-left:-10px;
    min-height: 25px;
    flex-grow: 1;
    color:#fefefe;
    text-align: left;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    background-color: transparent;
}

textarea[formControlName="shortDescription"] {
    resize: vertical;
    margin-top:-10px;
    margin-left:-10px;
    min-height: 50px;
    flex-grow: 1;
    color:#fefefe;
    text-align: left;
    font-size: 1.1rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight:100;
    background-color: transparent;
}

textarea[formControlName="text"] {
    resize: vertical;
    margin-top:-10px;
    margin-left:-10px;
    min-height: 50px;
    flex-grow: 1;

    color:#fefefe;
    text-align: left;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight:100;
    background-color: transparent;
    font-size: 1.1rem;
}

// ****************** Poll Styles ******************



.poll-option-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1px solid  var(--gray-600); /* This line adds the thin gray line */
    padding: 5px; /* Optional, adds some vertical padding */
}


.poll-option-group {
    display: flex;
    align-items: center;
}


.poll-question {
    margin-top: 0px;
    color: var(--bluegray-100)  !important;
    text-align: left;

    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1 rem;
}

.poll-option {
    margin-left: 10px;
    color: var(--bluegray-100)  !important;
    background-color: transparent;
    font-family: 'Roboto light', sans-serif;
    font-weight: 400;
    font-size: 0.9 rem;
}

.poll-count-group {
    display: flex;
    align-items: center;
}

.poll-option-votes {
    width: 50px;
    margin-right: 5px;
    margin-left: 10px;
    text-align: right;
    color: var(--green-400);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    background-color: transparent;
    font-size: 0.9rem;
}

.poll-option-percent {
    width: 40px; /* Set a fixed width */
    margin-right: 10px;
    text-align: right;
    color: var(--orange-400);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    background-color: transparent;
    font-size: 0.9rem;
}




// ******************* Commentars ****************

/* Allow only vertical resizing */
textarea {
    color:#fefefe;
    text-align: left;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight:100%;
    font-size: 1.1rem;

    width:97%;
    margin-left: 10px;
    margin-right:20px;
    border: 0.1px solid var(--gray-600);
    border-radius:8px;
    background-color: transparent;
    resize: vertical;
}

.comment-text{
    margin-top: 5px;
    color: var(--bluegray-200)  !important;
    text-align: left;
    font-size: 1rem;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-weight: 300;
}

.comment-datetime{
    color:  #eeeeee;
    padding: 0px;
    text-align: left;
    margin-left: 20px;
    margin-right:4px;

    white-space: nowrap; /* Keeps the text on one line */
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    font-size:0.8rem;
}

.comment-avatar-container {
    position: relative; /* Relative position to its parent */
    margin-right:10px;
    width: 25px;
    height: 25px;
    border: 1px solid rgba(56, 58, 100, 1);
    border-radius: 50%; /* Makes it circular */
    display: flex; /* Makes use of Flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    overflow: hidden; /* Hides excess parts of any child */
    flex-shrink: 0;
}

.comment-avatar-container img {
    object-fit: cover; /* Scales and crops the image */
    width: 100%;
    height: 100%;
}

.post-comment-button {
    height: 22px;
    border: 0.5px solid gray;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1rem;

    /* To vertically align the text and icon */
    display: flex;
    align-items: center;
    justify-content: center;

    /* PrimeNG-specific classes to override */
    .pi {
        font-size: 1rem;
        vertical-align: middle;
    }

    .p-button-label {
        font-size: 1rem;
        vertical-align: middle;
    }
}

// ***************** Complaints ******************

textarea[formControlName="description"] {
    resize: vertical;
    margin-top:0px;
    margin-left:-10px;
    min-height: 100px;
    flex-grow: 1;
    color:#fefefe;
    text-align: left;
    font-size: 1rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight:100;
    background-color: transparent;
}

.white-icon .pi.pi-trash {
    color: white;
}

/* Reduce padding in the table rows */
.p-datatable .p-datatable-tbody > tr > td {
    padding: 2px 10px;
}

.white-icon .pi {
    color: #dddddd !important; /* The !important might be needed to override PrimeNG styles */
}

p-table table tr {
    border-bottom: 1px solid #ccc;
}

/* Removes bottom border for the last row */
p-table table tr:last-child {
    border-bottom: none;
}

.tabview-custom {
    // Center the tab panel headers
    .p-tabview-nav {
        justify-content: center;

        // Change color of the header
        li {
            //             background-color: #ff5722;
            .p-tabview-nav-link {
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 1rem;

            }
        }

        .p-highlight {
            background-color: var(--gray-700) !important;
        }
    }

    // Change border
    .p-tabview-panels {
        border: 0px solid #000;
        border-radius: 4px !important;
        height:420px;
        //         height: 22px;
        //         min-width:60px !important;
        //         max-width:120px !important;
        //         padding: 2px;
        //         padding-left: 4px;
        //         padding-right: 8px;
        //         margin:2px;
        //                     border: 0.1px solid var(--gray-200) !important;
        //                     background-color: var(--gray-700) !important;
        //                     color: white;
    }

    .p-tabview-panel {
        margin: 2px;
        padding: 2px;
    }
}


// Opshtinsko Logo

.opshtina-card {
    display: flex;
    height: 60px;
    border: 0.1px solid var(--gray-500);
    border-radius: 0px 8px 8px 0px;
    /* additional styles */
}

.logo {
    width: 60px;
    height: 60px;
    /* Logo styles */
}

.opshtina-right {
    flex-grow: 1;
}

.opshtina-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.opshtina-table th,
.opshtina-table td {
    width: calc(100% / 3);
    height: 30px;
    text-align: center;
    vertical-align: middle;
    border-left: 0.5px solid var(--gray-500);
}

.opshtina-table th:first-child,
.opshtina-table td:first-child {
    border-left: none;
}

.vert-line-50 {
    height: 50%;
    width: 0.5px;
    background: var(--gray-500);
    position: absolute;
}

.horz-line {
    height: 0.5px;
    background: var(--gray-500);
    width: 50%;
    position: absolute;
}


.dotted-line {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-image: linear-gradient(to right, orange, transparent);
    border-image-slice: 1;
}
.hidden-radio {
    display: none;
}

.custom-radio label::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 10px;
}

.custom-radio input[type="radio"]:checked + label::before {
    background-color: #ccc;
    border: none;
}

.custom-radio input[type="radio"]:disabled:checked + label::before {
    background-color: gray;
    border: 1px solid #ccc;
    border-color: #ccc;
}


.full-width-select-button {
    width: 100%;
}

.article-scroll-container {
    width: 100%;
    max-height: calc(100vh - 150px); /* Adjust height dynamically to fit the viewport */
    overflow-y: auto; /* Keep the vertical scroll */
    //border: 4px solid blue; /* For debugging layout */
    padding: 2px; /* Optional for inner spacing */
    box-sizing: border-box;
}

.article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 32.2%));
    gap: 16px;
    // border:1px solid orange;
    height:1000px;
}

.article-box {
    border:1px solid gray;
    border-radius:10px;
    max-height:710px;
}

.fitr-logo {
    max-width: 100px;
    margin-top: 10px;
    display: inline-block; /* Ensures the image respects the size constraints */
}

// ******************* ALert Screens

.alert-gallery-container  {
    display: flex;
    position: relative;
    height: 350px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    //overflow: hidden;
}

.alert-gallery-container img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    vertical-align: middle;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.gallery-left {
    margin-top:30px;
    width: 40%;
    /* Additional styling as needed */
}

.gallery-right {
    padding:30px;
    width: 60%;
    /* Additional styling as needed */
}

.alert-gallery-nav-btn {
    position: absolute;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust positioning to be perfectly centered */
    background-color: rgba(255, 255, 255, 0.4); /* Slight white background */
    border: none;
    font-size: 18px;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 50%; /* Make buttons circular */
}

.alert-gallery-nav-btn:hover {
    background-color: rgba(241, 109, 22, 0.99); /* Stronger background on hover */
}

.alert-gallery-prev-btn {
    left: 10px; /* Position to the left */
}

.alert-gallery-next-btn {
    right: calc(60%  + 20px); /* Adjust to 50% of the container width minus half of the button width */
}


// Alert Box View

.alert-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px,45%));
    grid-template-rows: repeat(1, minmax(570px, 570px));
    //overflow: hidden;
    gap: 16px;
}

.alert-scroll-container {
    width:100%;
    max-height: 580px; /* Adjust as necessary */
    //overflow-y: auto;
    overflow-y: scroll;
}

.alert-box {
    border:1px solid gray;
    border-top: none; /* Remove the top border */
    border-radius:10px;
    //overflow: hidden;
}

.alert-box {
    border-radius: 10px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.alert-type {
    position: absolute;
    z-index:2;
    top: -1px; /* Position to cover where the top border was */
    left: -1px; /* Extend one pixel to the left to cover the border */
    right: -1px; /* Extend one pixel to the right to cover the border */
    width: calc(100% + 2px); /* Adjust width to cover the extended area */
    height:36px;
    background-color: var(--pink-600);
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left:22px;
    border-top-left-radius: 10px;
    border-top-right-radius:10px;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size:1.2rem;
}

.alert-type .pi {
    margin-left: 10px;
    color:  #fefefe; /* This will make the icons same color as your text */
}

.alert-type-text{
    color:  #fefefe;
    text-align: center;
    white-space: nowrap; /* Keeps the text on one line */
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size:1.2rem;
}


.alert-author-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 32px;
    width: 100%;
    background: linear-gradient(to left, transparent 0%, rgba(56, 58, 100, 1) 90%, rgba(56, 58, 100,  1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0e0e0e;
}

.alert-author-label {
    color:  #fefefe;
    text-align: left;
    margin-bottom: 1px;
    margin-left: 75px;
    margin-right: auto;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 1rem;
}

.alert-author-name {
    padding: 2px;
    margin-bottom: 1px;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alert-author-avatar-container-background {
    position: absolute;
    bottom: 0px;
    left: 2px;
    width: 60px;
    height: 60px;
    background-color: rgba(56, 58, 100, 1);
    border-radius: 50%;
    display: flex;
}

.alert-author-avatar-container {
    position: absolute;
    bottom: 6px;
    left: 6px;
    width: 48px;
    height: 48px;
    border-radius: 50%; /* Makes the container circular */
    display: flex;
    overflow: hidden; /* Hides the excess portion of the image */
}

.alert-author-avatar-container img {
    object-fit: cover; /* Scales and crops the image */
    width: 100%;
    height: 100%;
}

.alert-text-card {
    position: relative;
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 12px;
    width: calc(100% - 40px);
    min-width:200px;
    background: rgba(0, 0, 0, 0.01);
    //padding: 10px;
}

// Budget Box View

.budget-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 10px;
}

.budget-box {
    min-height: 500px;
    border: 1px solid darkgray;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    min-width: 400px; /* Mi5imum width for the double-width box */
    display: flex;
    flex-direction: column; /* Stack children vertically */
}
.chart-container {
    display: flex;
    justify-content: center;
    text-align: center; /* Center the chart horizontally */
    //max-width: 220px;
    //margin: 0 auto;
}
.box-content {
    flex-grow: 1; /* Allow this section to grow and push table to the bottom */
    display: flex;
    flex-direction: column;
}

.table-container {
    margin-top: auto; /* Push the table to the bottom */
}



.budget-title  {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: lighter;
    font-size:18px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px
}

.double-width-box {
    grid-column-start: span 2; /* This spans two columns */
    min-width: 300px; /* Minimum width for the double-width box */
    max-width: 640px;
}



//********************  This is styling for the buttons on top ******************
.p-buttonset .p-button:last-of-type {
    justify-content: center;
    margin: 0;
    padding: 1rem;
}


.top-text {
    min-width: 150px;
    height: 2rem;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1rem;
    color: white;
    text-align: center; /* Horizontally center the text */
    line-height: 2rem; /* Vertically center the text */
    margin: 0;
    padding: 0;
}

.top-button {
    height: 1rem; /* Adjust height */
    font-family: 'Roboto Bold', sans-serif;
    font-size: 1rem; /* Adjust font size */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white;
    justify-content: center;
    align-items: center;
}


.toggleViewButton {
    font-family: 'Roboto Bold', sans-serif;
    font-size: 1.3rem; /* Adjust font size */
    height:2rem;
    width:2rem;
    padding:0;
    color: whitesmoke;
    justify-content: center;
    align-items: center;
}


.top-button i,
.custom-button span {
    vertical-align: middle; /* Vertically align the icon and text */
}



.search-button {
    height: 1rem; /* Adjust height */
    font-family: 'Roboto Bold', sans-serif;
    font-size: 1rem; /* Adjust font size */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white;
    justify-content: center;
    align-items: center;
}

.search-button i,
.custom-button span {
    vertical-align: middle; /* Vertically align the icon and text */
}



// ***************** Filter

.custom-container * {
    //     border:0.5px solid yellow;
    font-size: 1rem;
    border-radius: 0 !important;  /* Remove rounded corners */
}

.custom-container i.pi {
    align-self: center;  /* Vertically center the icons */
    font-size: 1rem;
}

.search-button {
    background-color: var(--primary-400);
    display: flex;
    align-items: center;
    height:2rem;
    width: 90px;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 0px !important;
}

.custom-container .p-button {
    display: flex;
    align-items: center;
    height:2rem;
    width: 90px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.custom-container .pInputText {
    align-items: center;
    height:2rem;
}

.custom-multiselect-width {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 0.8rem!important;
    height:2rem;
    padding:4.5px !important;
    margin:0px !important;
    line-height: 0.5rem !important;
    width: 300px !important;
}

.custom-dropdown-width {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 0.8rem!important;
    height:2rem;
    padding:4.5px !important;
    margin:0px !important;
    line-height: 1rem !important;
    width: 300px !important;
}

.custom-calendar-width {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 1rem!important;
    height:2rem;
    margin:0px !important;
    line-height: 1rem !important;
    width:105px !important;
}

.custom-autocomplete-width  {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 1rem!important;
    height:2rem;
    padding:4.5px !important;
    margin:0px !important;
    line-height: 1rem !important;
    width: 210px !important;
}

.p-input-icon-left .pi-calendar2 {
    font-size: 20px !important;
    width: 20px !important;
}

.custom-container .p-dropdown {
    display: flex;
    align-items: center;
    height:2rem;
    width: 130px; /* Fixed width */
}

.custom-container .custom-dropdown {
    display: flex;
    align-items: center;
    width: 130px;
}

.custom-dropdown .p-dropdown-panel .p-dropdown-items {
    width: 130px;
    max-height: 16rem;
    overflow-y: auto;
}


.flex-container {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Start alignment */
}

.left-group {
    flex: 0 0 auto; /* Don't grow, don't shrink */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.center-group {
    //width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px; /* Adjust as needed */
    margin-right: 10px; /* Adjust as needed */
}

.right-group {
    margin-right:10px;
    margin-left: auto; /* Push to the far right */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.left-group > *, .right-group > * {
    margin: 0 10px; /* consistent margin for all buttons */
}


.chart-table-cell {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: lighter;
    font-size: small;
    padding: 5px;
    margin: 1px;
    text-align: right;
}


.chart-table-cell-amount {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    font-size: small;
    padding: 5px;
    margin: 1px;
    text-align: right;
    width: 100px;
}


.dialog-content {
    /* Add some padding at the bottom to make space for the floating footer */
    margin-left: 200px;
    margin-right: 200px;
    margin-bottom: 80px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    padding-bottom: 20px; /* Adjust as necessary based on the height of your footer */
    overflow-y: auto;
}

.terms-header {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    padding-bottom: 5px;
    color:var(--pink-400);
}

.terms-header-small {
    font-family: 'Roboto Condensed Light', sans-serif;
    font-weight: normal;
    font-size: 1.2rem;
    margin-bottom:0;
    padding-bottom: 0;
    color:var(--blue-100);
}

.terms-subheader {
    font-family: 'Roboto Condensed Light', sans-serif;
    font-weight: normal;
    font-size: 1.2rem;
    color:whitesmoke;
}

.terms-body {
    font-family: 'Roboto Condensed Light', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    margin-top:0.5rem;
    color:whitesmoke;
}

.custom-separator {
    margin: 0 0;
    height:1px;
    width:100%;
    border: 1px solid red; /* Adjust color as needed */
}


.footer {
    color:lightgray;
    //background: var(--body-bg);
    background: linear-gradient(to bottom, var(--gray-800),  var(--gray-900));

    text-align: center;
    box-shadow: 5px -5px 5px -6px rgba( 15,15,15, 0.9);
    border-radius:5px;
    padding: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 96%;
    margin-left:2%;
    margin-right:2%;
    height: 40px;
    z-index: 1000;
    display: flex;
}


/* Container for the logo and the sponsor text */
.sponsor-container {
    position: absolute; /* Set the sponsor-container to be absolutely positioned */
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-bottom: 10px;
}

/* Logo styles */
.sponsor-logo {
    //border: 1px solid red;
    width: auto; /* adjust as needed */
    height: 40px; /* keeps the image aspect ratio */
    margin-right: 0; /* space between logo and sponsor text */
    margin-top: 2px;
    margin-bottom: 2px;
}

/* Sponsor Text */
.sponsor-text {
    width:100px;
    background-color: white;
    text-align: center;
    margin-left: -5px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    //border: 1px solid blue;
}

.footer-text {
    width:100%;
    text-align: center;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-weight: normal;
    font-size: 1.1rem;
    margin-top:0.8rem !important;
}

.custom-link {
    text-decoration: none;
    border-bottom: 0px solid; /* Adjust thickness here */
    padding-bottom: 0px; /* Adjust padding to control distance from text */
}



.back-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 500px;
    padding: 40px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.field {
    color:darkgray;
}

.eMail-formLabel {
    color:black;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-weight: normal;
    font-size: 1.1rem;
}

.back-box form .field:nth-child(-n+3) {
    grid-column: 1;
}

.back-box form .field:nth-child(n+4) {
    grid-column: 2;
}

.button-container {
    display: flex;          /* Enables Flexbox */
    justify-content: center; /* Centers the buttons horizontally */
    gap: 20px;              /* Space between buttons */
}

.button-containerLogin {
    margin-top: 32px;
    justify-content: space-between; /* Centers the buttons horizontally */
    gap: 20px;              /* Space between buttons */
}

.custom-divider {
    //padding-bottom: 13px;
    border-bottom: 1px solid #999; /* Change color and thickness as needed */
}


.p-inputgroup {
    /* Your existing styles for p-inputgroup */
    margin-top:5px;
}


.input-grp {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 8px;
}

.input-grp {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 8px;
}

.input-frm {
    padding: 0 0 0 10px;
    border: 0;
    border-radius: 7px 0 0 7px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.1rem;
    color: whitesmoke;
    background-color: var(--indigo-700);
    width: 10rem;
    justify-content: start;
}

.input-icon {
    font-size: 12px;
    margin-right: 10px;
}

.input-txt {
    height: 28px;
    min-width:33rem;
    font-family: 'Roboto', sans-serif;
    color: whitesmoke;
    font-size: 1.1rem;
    border-radius: 0 !important;
}


.opop-viewtext-head {
    color: whitesmoke;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.3rem;
    margin-top: 40px;
    margin-left: 2px;
    margin-bottom: 0px;
}


.horizontal-line-thick-margins::after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background: linear-gradient(to right, var(--orange-500), var(--gray-50));
    margin-bottom: 10px;
    margin-top: 3px;
}

.horizontal-line-thick-margins-white::after {
    content: "";
    display: block;
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, var(--orange-500), white);
    margin-bottom: 10px;
    margin-top: 3px;
}


.opop-table {
    width: 100%;
    border-collapse: collapse;
}

.opop-table .tableDataKey {
    width: 70%;
    border-right: 0px solid  var(--gray-500);
    border-left:  0px solid  var(--gray-500);
}

.opop-table .tableDataValue {
    width: 100%;
}

.opop-table td {
    padding: 4px;
}

.opop-table tr:not(:last-child) {
    border-bottom: 0.1px solid var(--gray-500);
    height: 30px;
}


.tableDataKeyIn {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 1rem;
    color:whitesmoke;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tableDataKey {
    vertical-align: top;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 1.1rem;
    color:white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tableDataValueIn {
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color:whitesmoke;
}

.tableDataValue {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    color:white;
    padding-left: 10px !important;
}

.tableDataValue.right {
    text-align: left;
    margin-left:10px;
    //vertical-align: middle;
}

tableDataValue.center {
    text-align: left;
}

.tableDataHeader {
    text-align: left;
}

//text-900 font-medium text-xl line-height-3 mb-4

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-spacing {
    margin-right: 10px; /* Adjust the space as needed */
    //margin-left: 20px;
}

.center-container-budget {

}

.wallet-button {
    background-color: linear-gradient(to bottom, var(--pink-500), var(--pink-400));
    padding-left: 20px;
    padding-right: 20px;
    height:30px;
    color: white;
    border: none;
    margin: 2px;
    margin-top: 5px;
    border-radius: 4px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wallet-button-disabled-stop {
    background-color: #fefefe; /* Or any color indicating disabled state */
    color: #767676; /* Dimmed text color for disabled state */
}

/* Increase spacing between options */
.report-option-spacing {
    margin-bottom: 12px; /* adjust as needed */
}

/* Custom font for the title */
.report-title {
    font-family: 'Roboto Condensed Bold', sans-serif; /* choose your preferred font */
    font-size: 1.3rem; /* adjust size as needed */
}

/* Custom font for the text */
.report-text {
    font-family: 'Roboto Condensed', sans-serif; /* choose your preferred font */
    font-size: 1.1rem; /* adjust size as needed */
    margin-bottom:20px;
}

/* Custom font for the options */
.report-option {
    font-family: 'Roboto Condensed', sans-serif; /* choose your preferred font */
    font-size: 1rem; /* adjust size as needed */
}

.request-otp-button {
    background-color: transparent;
    color: orange;
    border: none;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 1.25rem;
    cursor: pointer;
}

.request-otp-button:disabled {
    color: gray;
    cursor: not-allowed;
}

.request-otp-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    //height: 100vh; /* Take up full viewport height */
}

.otp-info-text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.3rem;
    color:lightgrey;
}

.otp-numbers-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    color:lightgrey;
}

.otp-numbers-fillin {
    font-family: 'Roboto Bold', sans-serif;
    font-size: 1.3rem;
    color:lightskyblue;
}


.p-float-label.with-icon {
    display: flex;
    align-items: center;
}

.p-float-label.with-icon .icon {
    position:absolute;
    left: 0.5rem;
    //margin-right: 0.5rem; /* Space between icon and label */
}

.p-float-label.with-icon label {
    position:absolute;
    left: 2rem;
    //margin-right: 0.5rem; /* Space between label and input field */
}

/* Ensure the input field takes up the remaining space */
.p-float-label.with-icon input[type="text"] {
    position:absolute;
    left: 8.5rem;
    flex-grow: 1;
}

.copyright-container {
    position: relative;
    width:300px;
    margin-top:32px;
    margin-bottom:32px;
    color:var(--gray-100);
    /* Other styles for the container */
}

/* Adjust the class name as necessary */
copyright-text {
    border:2px solid red;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    /* Your existing styles */
    color: var(--color-secondary); /* Example for text color */
    font-size: 0.875rem; /* Example for text size */
    margin-right: 0.75rem; /* Adjust as needed */
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%; /* Full viewport height */
    text-align: center;
}

.file-upload-section {
    max-width: 400px; /* Optional: restrict width for better layout */
}

.table-container .chart-table-cell.top-aligned {
    display: inline-block !important; /* Ensure it's treated as a block within the cell */
    vertical-align: top !important;  /* Align the div to the top */
    height: 15px !important;         /* Adjust the square size */
    width: 15px !important;
    margin-top: 0 !important;        /* Remove any unintended margins */
    padding: 0 !important;           /* Remove any unintended padding */
}

::ng-deep .p-tabview-nav li .p-tabview-title {
    font-size: 36px !important; /* Adjust to your desired size */
}
